<template>
  <v-container
    class="w-100 px-0 confirmation liSteps liConfirmation"
    fluid
  >
    <!-- CONTENT -->
    <template v-if="pageContent != null && defaultTextContent !=null">
      <v-row class="mx-0">
        <!-- MOBILE HEADER -->
        <template v-if="isAppMobile">

          <v-img
            :src="`${apiPublicUrl}${pageContent.content.hero.imageMobile}`"
            :position="pageContent.content.hero.imagePositionMobile"
            width="100vw"
            height="250px"
            :style="`
                margin-top:${isAppMobile || isAppTablet? '50px' :'64px'};
                padding-top:${isAppMobile || isAppTablet? '100px' :'100px'};
            `"
            class="px-5"
          >
            <!-- CAMPAIGN LOGO -->
            <img
              :src="defaultTextContent.campaignLogo"
              class="liCampaignLogo mobile"
            />
            <!-- END CAMPAING LOGO -->
            <!-- TITLE -->
            <h1
              class="
                    text-h4
                    text-uppercase
                    mb-2
                    font-weight-bold
                    white--text
                    liTextShadow
                    liStepTitleMobile
                  "
              v-html="pageContent.content.hero.title"
            />
          </v-img>
        </template>
        <v-img
          :src="`${apiPublicUrl}${pageContent.content.hero.image}`"
          :position="pageContent.content.hero.imagePosition"
          class="hero blur"
          min-height="100vh"
        >
          <!-- SUCCESS! -->
          <template v-if="succesAlert">
            <v-overlay></v-overlay>
            <v-alert
              border="bottom"
              colored-border
              elevation="2"
              style="position:fixed; z-index:10; top:50%; left:50%; min-width 300px; width:50%; transform:translate(-50%,-50%)"
            >
              <v-row align="center">
                <v-col align="center">
                  <h3 class="text-h6 mt-3 mb-4">
                    {{pageContent.content.hero.successTitle}}
                  </h3>
                  <p class="text-p mt-3">
                    {{pageContent.content.hero.successMessage}}
                  </p>
                  <v-btn
                    class="bg-primary mb-4 px-10 text-white"
                    @click.prevent="$router.push(pageContent.content.hero.successCloseTo)"
                  >
                    {{pageContent.content.hero.successClose}}
                  </v-btn>
                </v-col>

              </v-row>

            </v-alert>
          </template>
          <!-- LOADING -->
          <v-overlay v-if="formLoading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-overlay>
          <!-- CAMPAIGN LOGO -->
          <img
            v-if="!isAppMobile"
            :src="defaultTextContent.campaignLogo"
            class="liCampaignLogo"
          />
          <!-- END CAMPAING LOGO -->
          <!-- CONFIRM QUESTION -->
          <v-sheet
            class="text-left px-5 pb-5 px-md-10 px-lg-15 pb-15 d-flex liContentLeft bg-black"
            style="overflow: hidden; "
            elevation="20"
            :min-width="`${isAppMobile ? '100%' :  isAppTablet ? '75%': '50%'}`"
            :max-width="`${isAppMobile ? '100%' :  isAppTablet ? '75%': '50%'}`"
            :style="`
                margin-top:${isAppMobile || isAppTablet? '0px' :'64px'};
            `"
            :min-height="`calc(100% - ${isAppMobile || isAppTablet? '50px' :'64px'})`"
            height="100%"
          >
            <div class="inner">
                <template v-if="!isAppMobile">
              <!-- TITLE -->
              <h1
                class="
                  title 
                  text-lg-h3 text-md-h4 text-sm-h5 text-h5 
                  text-uppercase
                  mb-2
                  font-weight-bold
                  white--text
                  liTextShadow
                "
                v-html="pageContent.content.hero.title"
              />
                </template>
              <!-- SUBTITLE -->
              <div
                v-if="'subtitle' in pageContent.content.hero && pageContent.content.hero.subtitle != ''"
                class="text-p white--text liTextShadow"
                v-html="pageContent.content.hero.subtitle"
              />

              <!-- FORM -->
              <v-sheet
                class="pa-0 liForm mt-4"
                color="transparent"
                style="overflow: hidden;"
                max-height="calc(100% - 300px)"
              >

                <!-- CONFIRM-->
                <v-form
                  ref="formConfirm"
                  v-model="valid"
                  lazy-validation
                >

                  <!-- CONFIRM -->
                  <span class="d-block w-100">
                    <v-textarea
                      class="mt-0 pt-0"
                      filled
                      dark
                      counter
                      :rows="`${isAppMobile? 8 : 4}`"
                      auto-grow
                      :placeholder="pageContent.content.hero.placeholder"
                      v-model="question"
                      required
                      :rules="questionRules"
                    ></v-textarea>
                  </span>
                  <!-- NEXT -->
                  <span class="d-block w-100">
                    <div class="my-2">
                      <v-btn
                        class="text-white bg-primary"
                        @click.prevent="validate"
                        block
                      >
                        {{ pageContent.content.hero.linkLabel }}
                      </v-btn>
                    </div>
                  </span>
                </v-form>
                <!-- /END CONFIRM 1 -->
              </v-sheet>
            </div>
          </v-sheet>

        </v-img>
      </v-row>
    </template>
    <!-- LOADING -->
    <template v-else>
      <div
        class="text-center w-100 d-flex"
        style="height: 50vh"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="m-auto"
        />
      </div>
    </template>

  </v-container>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "Confirmation",
  name: "Confirmation",
  data() {
    return {
      allStores: [],
      succesAlert: false,
      apiPublicUrl: API_URL,
      formLoading: null,
      sheet: false,
      valid: true,
      question: "",
      defaultRules: [(v) => !!v || this.pageContent.content.hero.required],
      questionRules: [
        (v) => !!v || this.pageContent.content.hero.required,
        (v) =>
          v.length <= this.pageContent.content.hero.length ||
          this.pageContent.content.hero.required,
      ],
    };
  },
  created() {
    this.fetchURL(true);
  },
  watch: {
    page(val) {
      if (val.length == 0) {
        // console.log("page", "404");
        this.$router.push(`/${this.language}/404`);
      }
    },
    pageContent(val) {
      if (val != null) {
        this.$title = this.pageContent.default.pageTitle;
      }
    },
  },
  mounted() {
    if(this.dev){
      console.log("Language:  ", this.language);
      console.log("Website:   ", this.website);
      console.log("FullUrl:   ", this.fullUrl);
      console.log("MOUNTED     √");
    }
    this.fetchPageData();
  },
  computed: {
    email() {
      return this.$route.params.email;
    },
    page() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.$store.getters["liApiContent/getPage"](this.fullUrl);
      // IF THE PAGE IS A STATIC BLOCK
      return this.$store.getters["liApiContent/getCollection"](
        `page_data_${this.language}_${this.fullUrl}`
      );
    },
    pageContent() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.page != null ? JSON.parse(this.page.content) : null;

      // IF THE PAGE IS A STATIC BLOCK
      let result = null;
      if (this.page != null && this.page.length != 0) {
        let block = JSON.parse(this.page[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
  },
  methods: {
    validate() {
      this.$refs.formConfirm.validate();
      this.formLoading = true;
      setTimeout(() => {
        if (this.valid && this.email != "" && this.email != undefined) {
          let _data = {
            email: this.email,
            motivation: this.question.trim(),
          };
          this.$store
            .dispatch("liApiStore/POST_FORM2", _data)
            .then((response) => {
              if (response) {
                this.succesAlert = true;
                this.formLoading = false;
              }
            });
        } else {
          this.formLoading = false;
        }
      }, 1000);
    },
  },
};
</script>
