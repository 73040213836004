<template>
  <v-container
    class="w-100 px-0"
    fluid
  >
    <template v-if="pageContent != null">
      <!-- hero -->
      <v-row class="mx-0 px-0">
        <v-img
          :src="`${apiPublicUrl}${pageContent.content.hero.image}`"
          :position="pageContent.content.hero.imagePosition"
          min-height="100vh"
          height="100vh"
          class="hero blur"
        >
          <v-row
            class="fill-height mt-3"
            align="center"
            justify="center"
          >
            <div class="white--text text-center">
              <h1
                class="
                title center-title
                  text-lg-h2 text-md-h3 text-sm-h3 text-h5 text-uppercase
                  li-text-shadow
                  mb-2
                  font-weight-bold
                "
                v-html="pageContent.content.hero.title"
              />
              <v-sheet
                class="text-sm-h5 text-p white--text text-center li-text-shadow m-auto"
                v-html="pageContent.content.hero.subtitle"
                color="transparent"
                max-width="80%"
              />
              <template v-for="(btn,n) in pageContent.content.hero.buttons">
                <!-- x-large -->
                <v-btn
                  :to="btn.to"
                  :color="btn.color"
                  light
                  :class="btn.class"
                  :key="n"
                  class="mt-5"
                >
                  {{btn.text}}
                </v-btn>
              </template>
            </div>
          </v-row>
        </v-img>
      </v-row>
    </template>
    <!-- LOADING -->
    <template v-else>
      <div
        class="text-center w-100 d-flex"
        style="height: 50vh"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="m-auto"
        />
      </div>
    </template>

  </v-container>
</template>

<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "",
  name: "PageNotFound",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  created() {
    this.fetchURL();
  },
  watch: {
    pageContent(val) {
      if (val != null) {
        this.$title = this.pageContent.default.pageTitle;
      }
    },
  },
  mounted() {
    if (this.dev) {
      console.log("START    -- pageNotFound");
      console.log("Language:  ", this.language);
      console.log("Website:   ", this.website);
      console.log("FullUrl:   ", this.fullUrl);
      console.log("END      -- pageNotFound");
    }

    this.fetchPageDataPageNotFound();
  },
  computed: {
    page() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.$store.getters["liApiContent/getPage"](this.fullUrl);
      // IF THE PAGE IS A STATIC BLOCK
      return this.$store.getters["liApiContent/getCollection"](
        `page_data_${this.language}_page_not_found`
      );
    },
    pageContent() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.page != null ? JSON.parse(this.page.content) : null;

      // IF THE PAGE IS A STATIC BLOCK
      let result = null;
      if (this.page != null) {
        let block = JSON.parse(this.page[0].content);
        console.log(block);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
        console.log(result);
      }
      return result;
    },
  },
  methods: {},
};
</script>
